import React from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/analytics-wiki"
);

const StyledMiddle = styled.div`
  h1 {
    text-align: center;
  }

  .cardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  margin: 0vmin 1vmin;
  .container {
  }

  .related-content-card {
    max-width: 27%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
  }

  @media all and (max-width: 900px) {
    .related-content-card {
      max-width: 43%;
    }
  }

  @media all and (max-width: 500px) {
    .cardcontainer {
      flex-direction: column;
      align-items: center;
    }

    .related-content-card {
      max-width: 95%;
      min-width: 80%;
    }
  }
`;

const breadCrumbLevels = {
  Home: "/en",
  FAQ: "/en/analytics-wiki"
};

const QUERY = graphql`
  query wikiAnalyticsListEN {
    allMdx(filter: { fields: { collection: { eq: "wikiAnalytics" } }, frontmatter: { language: { eq: "en" } } }) {
      edges {
        node {
          id
          fields {
            slug
            relativePath
          }
          fileAbsolutePath
          frontmatter {
            slug
            language
            title
          }
        }
      }
    }
  }
`;

const AnalyticsWikiEN = props => {
  const data = useStaticQuery(QUERY);

  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Analytics Wiki - Definitions & Explanations for Analytics Terms"
          lang="en"
          description="Analytics Wiki - Definitions & Explanations for Analytics Terms"
          alternateLangs={alternateLangs}
        />
        <MainContent maxWidth="1000">
          <StyledMiddle>
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
            <div className="container">
              <H as="h1">Analytics Wiki</H>
              <ul>
                {data.allMdx.edges.map(edge => (
                  <li key={`item-${edge.node.fields.slug}`}>
                    <Link to={edge.node.fields.relativePath}>
                      {edge.node.frontmatter.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </StyledMiddle>
        </MainContent>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(AnalyticsWikiEN);
